import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://api-staging.iniaku-international.id/',
  baseURL: process.env.VUE_APP_BACKEND_URL,
  // timeout: 1000,
  headers: { "Content-Type": "application/json" },
  responseType: 'json'
})

Vue.prototype.$http = axiosIns

export default axiosIns