export default [
    {
        path: '/mst/daerah/kelurahan',
        redirect: '/mst/daerah/kelurahan',
        name: 'mstDaerahKelurahan',
        component: () => import('@/views/NestedRoute.vue'),
        meta: {
            pageTitle: 'Kelurahan',
            breadcrumb: [
                {
                    text: 'Master',
                },
                {
                    text: 'Daerah',
                },
                {
                    text: 'Kelurahan',
                    active: true,
                },
            ],
        },
        props: true,
        children: [
            {
                path: '',
                name: 'mstDaerahKelurahanIndex',
                component: () => import('@/views/mst/daerah/kelurahan/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mst-daerah-kelurahan',
                    pageTitle: 'Kelurahan',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Daerah',
                        },
                        {
                            text: 'Kelurahan',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: '/mst/daerah/kelurahan/tambah',
                name: 'mstDaerahKelurahanTambah',
                component: () => import('@/views/mst/daerah/kelurahan/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mst-daerah-kelurahan',
                    pageTitle: 'Tambah Kelurahan',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Daerah',
                        },
                        {
                            text: 'Kelurahan',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/daerah/kelurahan/ubah/:id',
                name: 'mstDaerahKelurahanUbah',
                component: () => import('@/views/mst/daerah/kelurahan/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mst-daerah-kelurahan',
                    pageTitle: 'Ubah Kelurahan',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Daerah',
                        },
                        {
                            text: 'Kelurahan',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]