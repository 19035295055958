export default [
    {
        path: '/mst/rs-klinik',
        redirect: '/mst/rs-klinik',
        name: 'mstRsKlinik',
        component: () => import('@/views/NestedRoute.vue'),
        meta: {
            pageTitle: 'RS / Klinik',
            breadcrumb: [
                {
                    text: 'Master',
                },
                {
                    text: 'RS / Klinik',
                    active: true,
                },
            ],
        },
        children: [
            {
                path: '',
                name: 'mstRsKlinikIndex',
                component: () => import('@/views/mst/rsKlinik/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mst-rsKlinik',
                    pageTitle: 'RS / Klinik',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'RS / Klinik',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/rs-klinik/tambah',
                name: 'mstRsKlinikTambah',
                component: () => import('@/views/mst/rsKlinik/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mst-rsKlinik',
                    pageTitle: 'Tambah RS / Klinik',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'RS / Klinik',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/rsKlinik/ubah/:id',
                name: 'mstRsKlinikUbah',
                component: () => import('@/views/mst/rsKlinik/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mst-rsKlinik',
                    pageTitle: 'Ubah RS / Klinik',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'RS / Klinik',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]