export default [
    {
        path: '/mst/daerah/kecamatan',
        redirect: '/mst/daerah/kecamatan',
        name: 'mstDaerahKecamatan',
        component: () => import('@/views/NestedRoute.vue'),
        meta: {
            pageTitle: 'Kecamatan',
            breadcrumb: [
                {
                    text: 'Master',
                },
                {
                    text: 'Daerah',
                },
                {
                    text: 'Kecamatan',
                    active: true,
                },
            ],
        },
        props: true,
        children: [
            {
                path: '',
                name: 'mstDaerahKecamatanIndex',
                component: () => import('@/views/mst/daerah/kecamatan/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mst-daerah-kecamatan',
                    pageTitle: 'Kecamatan',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Daerah',
                        },
                        {
                            text: 'Kecamatan',
                            active: true,
                        },
                    ],
                },
                props: true,
            },
            {
                path: '/mst/daerah/kecamatan/tambah',
                name: 'mstDaerahKecamatanTambah',
                component: () => import('@/views/mst/daerah/kecamatan/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mst-daerah-kecamatan',
                    pageTitle: 'Tambah Kecamatan',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Daerah',
                        },
                        {
                            text: 'Kecamatan',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/daerah/kecamatan/ubah/:id',
                name: 'mstDaerahKecamatanUbah',
                component: () => import('@/views/mst/daerah/kecamatan/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mst-daerah-kecamatan',
                    pageTitle: 'Ubah Kecamatan',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Daerah',
                        },
                        {
                            text: 'Kecamatan',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]