import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    global: {
      state: {
        idInterval: 0
      },
      mutations:{
        change(state, idInterval){
          state.idInterval = idInterval
        }
      }
    }
  },
  strict: process.env.DEV,
})
