export default [
    {
        path: '/pemeriksaan/pemeriksaan',
        redirect: to => ({
            name: 'pemeriksaanPemeriksaanDraf',
            params: {
                status: 'draf'
            }
        }),
        name: 'pemeriksaanPemeriksaan',
        component: () => import('@/views/NestedRoute.vue'),
        children: [
            {
                path: '/pemeriksaan/pemeriksaan/draf',
                name: 'pemeriksaanPemeriksaanDraf',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'pemeriksaan-pemeriksaan-draf',
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Draf',
                            active: true,
                        },
                    ],
                },
                props: { status: 'draf' }
            },
            {
                path: '/pemeriksaan/pemeriksaan/batal',
                name: 'pemeriksaanPemeriksaanBatal',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'pemeriksaan-pemeriksaan-batal',
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Batal',
                            active: true,
                        },
                    ],
                },
                props: { status: 'batal' },
            },
            {
                path: '/pemeriksaan/pemeriksaan/register',
                name: 'pemeriksaanPemeriksaanRegister',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'pemeriksaan-pemeriksaan-register',
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Register',
                            active: true,
                        },
                    ],
                },
                props: { status: 'register' },
            },
            {
                path: '/pemeriksaan/pemeriksaan/processing',
                name: 'pemeriksaanPemeriksaanProcessing',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                redirect: to => ({
                    name: 'pemeriksaanPemeriksaanProcessingProcessing',
                    params: {
                        status: 'processing',
                        subStatus: 'processing'
                    }
                }),
                props: { status: 'processing' },
                meta: {
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Processing',
                            active: true,
                        },
                    ],
                },
                children: [
                    {
                        path: '',
                        name: 'pemeriksaanPemeriksaanProcessingProcessing',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/Processing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-processing-processing',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Processing',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'processing' },
                    },
                    {
                        path: '/pemeriksaan/pemeriksaan/processing/susul',
                        name: 'pemeriksaanPemeriksaanProcessingSusul',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/Processing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-processing-susul',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Processing',
                                },
                                {
                                    text: 'Susul',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'susul' },
                    },
                    {
                        path: '/pemeriksaan/pemeriksaan/processing/pld',
                        name: 'pemeriksaanPemeriksaanProcessingPld',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/Processing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-processing-pld',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Processing',
                                },
                                {
                                    text: 'PLD',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'pld' },
                    },
                    {
                        path: '/pemeriksaan/pemeriksaan/processing/konfirmasi',
                        name: 'pemeriksaanPemeriksaanProcessingKonfirmasi',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/Processing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-processing-konfirmasi',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Processing',
                                },
                                {
                                    text: 'Konfirmasi',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'konfirmasi' },
                    }
                ],
            },
            {
                path: '/pemeriksaan/pemeriksaan/diagnosa',
                name: 'pemeriksaanPemeriksaanDiagnosa',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                redirect: to => ({
                    name: 'pemeriksaanPemeriksaanDiagnosaStandar',
                    params: {
                        status: 'diagnosa',
                        subStatus: 'standar'
                    }
                }),
                meta: {
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Diagnosa',
                            active: true,
                        },
                    ],
                },
                props: { status: 'diagnosa' },
                children: [
                    {
                        path: '/pemeriksaan/pemeriksaan/diagnosa/standar',
                        name: 'pemeriksaanPemeriksaanDiagnosaStandar',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/TabTitipProcessing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-diagnosa-standar',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Diagnosa',
                                },
                                {
                                    text: 'Standar',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'standar' },
                    },
                    {
                        path: '/pemeriksaan/pemeriksaan/diagnosa/titip-processing',
                        name: 'pemeriksaanPemeriksaanDiagnosaTitipProcessing',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/TabTitipProcessing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-diagnosa-titipProcessing',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Diagnosa',
                                },
                                {
                                    text: 'Titip Processing',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'titipProcessing' },
                    }
                ]
            },
            {
                path: '/pemeriksaan/pemeriksaan/konsultasi',
                name: 'pemeriksaanPemeriksaanKonsultasi',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'pemeriksaan-pemeriksaan-konsultasi',
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Konsultasi',
                            active: true,
                        },
                    ],
                },
                props: { status: 'konsultasi' }
            },
            {
                path: '/pemeriksaan/pemeriksaan/selesai',
                name: 'pemeriksaanPemeriksaanSelesai',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                redirect: to => ({
                    name: 'pemeriksaanPemeriksaanSelesaiStandar',
                    params: {
                        status: 'selesai',
                        subStatus: 'standar'
                    }
                }),
                meta: {
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Selesai',
                            active: true,
                        },
                    ],
                },
                props: { status: 'selesai' },
                children: [
                    {
                        path: '/pemeriksaan/pemeriksaan/selesai/standar',
                        name: 'pemeriksaanPemeriksaanSelesaiStandar',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/TabTitipProcessing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-selesai-standar',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Selesai',
                                },
                                {
                                    text: 'Standar',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'standar' },
                    },
                    {
                        path: '/pemeriksaan/pemeriksaan/selesai/titip-processing',
                        name: 'pemeriksaanPemeriksaanSelesaiTitipProcessing',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/TabTitipProcessing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-selesai-titipProcessing',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Selesai',
                                },
                                {
                                    text: 'Titip Processing',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'titipProcessing' },
                    }
                ]
            },
            {
                path: '/pemeriksaan/pemeriksaan/favorit',
                name: 'pemeriksaanPemeriksaanFavorit',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                redirect: to => ({
                    name: 'pemeriksaanPemeriksaanFavoritStandar',
                    params: {
                        status: 'favorit',
                        subStatus: 'standar'
                    }
                }),
                meta: {
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Favorit',
                            active: true,
                        },
                    ],
                },
                props: { status: 'favorit' },
                children: [
                    {
                        path: '/pemeriksaan/pemeriksaan/favorit/standar',
                        name: 'pemeriksaanPemeriksaanFavoritStandar',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/TabTitipProcessing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-favorit-standar',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Favorit',
                                },
                                {
                                    text: 'Standar',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'standar' },
                    },
                    {
                        path: '/pemeriksaan/pemeriksaan/favorit/titip-processing',
                        name: 'pemeriksaanPemeriksaanFavoritTitipProcessing',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/TabTitipProcessing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-favorit-titipProcessing',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Favorit',
                                },
                                {
                                    text: 'Titip Processing',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'titipProcessing' },
                    }
                ]
            },
            {
                path: '/pemeriksaan/pemeriksaan/semua',
                name: 'pemeriksaanPemeriksaanSemua',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Index.vue'),
                redirect: to => ({
                    name: 'pemeriksaanPemeriksaanSemuaStandar',
                    params: {
                        status: 'semua',
                        subStatus: 'standar'
                    }
                }),
                meta: {
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Semua',
                            active: true,
                        },
                    ],
                },
                props: { status: 'semua' },
                children: [
                    {
                        path: '/pemeriksaan/pemeriksaan/semua/standar',
                        name: 'pemeriksaanPemeriksaanSemuaStandar',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/TabTitipProcessing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-semua-standar',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Semua',
                                },
                                {
                                    text: 'Standar',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'standar' },
                    },
                    {
                        path: '/pemeriksaan/pemeriksaan/semua/titip-processing',
                        name: 'pemeriksaanPemeriksaanSemuaTitipProcessing',
                        component: () => import('@/views/pemeriksaan/pemeriksaan/status/TabTitipProcessing.vue'),
                        meta: {
                            action: 'read',
                            resource: 'pemeriksaan-pemeriksaan-semua-titipProcessing',
                            pageTitle: 'Pemeriksaan',
                            breadcrumb: [
                                {
                                    text: 'Pemeriksaan',
                                },
                                {
                                    text: 'Semua',
                                },
                                {
                                    text: 'Titip Processing',
                                    active: true,
                                }
                            ],
                        },
                        props: { subStatus: 'titipProcessing' },
                    }
                ]
            },
            {
                path: '/pemeriksaan/pemeriksaan/tambah',
                name: 'pemeriksaanPemeriksaanTambah',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'pemeriksaan-pemeriksaan',
                    pageTitle: 'Input Data Pasien',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Input Data Pasien',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/pemeriksaan/pemeriksaan/ubah/:id',
                name: 'pemeriksaanPemeriksaanUbah',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'pemeriksaan-pemeriksaan',
                    pageTitle: 'Pemeriksaan',
                    breadcrumb: [
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
            {
                path: '/pemeriksaan/pemeriksaan/download/:id',
                name: 'pemeriksaanPemeriksaanDownload',
                component: () => import('@/views/pemeriksaan/pemeriksaan/Download.vue'),
                meta: {
                    layout: 'full',
                    resource: 'Auth',
                },
                props: true
            },
        ]
    },
]