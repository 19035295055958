export default [
    {
        path: '/mst/pemeriksaan/makroskopik',
        redirect: '/mst/pemeriksaan/makroskopik',
        name: 'mstPemeriksaanMakroskopik',
        component: () => import('@/views/NestedRoute.vue'),
        meta: {
            pageTitle: 'Makroskopik',
            breadcrumb: [
                {
                    text: 'Master',
                },
                {
                    text: 'Pemeriksaan',
                },
                {
                    text: 'Makroskopik',
                    active: true,
                },
            ],
        },
        children: [
            {
                path: '',
                name: 'mstPemeriksaanMakroskopikGetData',
                component: () => import('@/views/mst/pemeriksaan/makroskopik/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mst-pemeriksaan-makroskopik',
                    pageTitle: 'Makroskopik',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Makroskopik',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/pemeriksaan/makroskopik/tambah',
                name: 'mstPemeriksaanMakroskopikTambah',
                component: () => import('@/views/mst/pemeriksaan/makroskopik/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mst-pemeriksaan-makroskopik',
                    pageTitle: 'Tambah Makroskopik',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Makroskopik',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/pemeriksaan/makroskopik/ubah/:id',
                name: 'mstPemeriksaanMakroskopikUbah',
                component: () => import('@/views/mst/pemeriksaan/makroskopik/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mst-pemeriksaan-makroskopik',
                    pageTitle: 'Ubah Makroskopik',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Makroskopik',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]