export default [
    {
        path: '/mst/tagihan/biaya',
        redirect: '/mst/tagihan/biaya',
        name: 'mstTagihanBiaya',
        component: () => import('@/views/NestedRoute.vue'),
        children: [
            {
                path: '',
                name: 'mstTagihanBiayaIndex',
                component: () => import('@/views/mst/tagihan/biaya/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mst-tagihan-biaya',
                    pageTitle: 'Biaya',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Biaya',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/tagihan/biaya/tambah',
                name: 'mstTagihanBiayaTambah',
                component: () => import('@/views/mst/tagihan/biaya/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mst-tagihan-biaya',
                    pageTitle: 'Tambah Biaya',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Biaya',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/tagihan/biaya/ubah/:id',
                name: 'mstTagihanBiayaUbah',
                component: () => import('@/views/mst/tagihan/biaya/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mst-tagihan-biaya',
                    pageTitle: 'Ubah Biaya',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Biaya',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]