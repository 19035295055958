import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
var vueCookies = require('vue-cookies')

//routes
import pengguna from './routes/mjmn/pengguna'
import fiksasi from './routes/mst/fiksasi'
import layanan from './routes/mst/layanan'
import lokasiAsalJaringan from './routes/mst/lokasiAsalJaringan'
import pembiayaan from './routes/mst/pembiayaan'
import pengambilanJaringan from './routes/mst/pengambilanJaringan'
import peran from './routes/mst/peran'
import auth from './routes/auth'
import jenisPemeriksaan from './routes/mst/pemeriksaan/jenisPemeriksaan'
import rsKlinik from './routes/mst/rsKlinik'
import sediaan from './routes/mst/pemeriksaan/sediaan'
import keteranganKlinis from './routes/mst/pemeriksaan/keteranganKlinis'
import diagnosaKlinis from './routes/mst/pemeriksaan/diagnosaKlinis'
import riwayatLabPa from './routes/mst/pemeriksaan/riwayatLabPa'
import makroskopik from './routes/mst/pemeriksaan/makroskopik'
import mikroskopik from './routes/mst/pemeriksaan/mikroskopik'
import morphology from './routes/mst/pemeriksaan/morphology'
import topography from './routes/mst/pemeriksaan/topography'
import icd from './routes/mst/pemeriksaan/icd'
import pemeriksaan from './routes/pemeriksaan/pemeriksaan'
import provinsi from './routes/mst/daerah/provinsi'
import kotaKabupaten from './routes/mst/daerah/kotaKabupaten'
import kecamatan from './routes/mst/daerah/kecamatan'
import kelurahan from './routes/mst/daerah/kelurahan'
import tagihan from './routes/tagihan'
import biaya from './routes/mst/tagihan/biaya'
import bank from './routes/mst/tagihan/bank'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/',
      component: () => import('@/views/NestedRoute.vue'),
      children: [
        {
          path: '',
          name: 'homeIndex',
          component: () => import('@/views/Home.vue'),
          meta: {
            action: 'read',
            resource: 'home',
            pageTitle: 'Home',
            breadcrumb: [
              {
                text: 'Home',
                active: true,
              },
            ],
          },
        },
      ]
    },
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@/views/Home.vue'),
    //   meta: {
    //     action: 'read',
    //     resource: 'home',
    //     pageTitle: 'Home',
    //     breadcrumb: [
    //       {
    //         text: 'Home',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    ...pengguna, ...auth, ...rsKlinik,
    ...peran, ...fiksasi, ...layanan, ...lokasiAsalJaringan, ...pembiayaan, ...pengambilanJaringan, ...keteranganKlinis, ...diagnosaKlinis, ...riwayatLabPa, ...makroskopik, ...mikroskopik, ...morphology, ...topography, ...icd, ...pemeriksaan, ...provinsi, ...kotaKabupaten, ...kecamatan, ...kelurahan, ...biaya, ...bank,
    ...jenisPemeriksaan, ...sediaan,
    ...tagihan,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = vueCookies.get('token')
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn)
      return next({ name: 'login' })

    // If logged in => not authorized
    let name = 'pemeriksaanPemeriksaanDiagnosa'
    getUserData().ability.findIndex((element, index) => {
      if (element.subject == 'pemeriksaan-pemeriksaan-draf' && element.action == 'read') {
        name = 'pemeriksaanPemeriksaanDraf'
        return true;
      }
    })
    return next({ name })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
