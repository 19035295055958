export default [
    {
        path: '/mst/pemeriksaan/icd',
        redirect: '/mst/pemeriksaan/icd',
        name: 'mstPemeriksaanIcd',
        component: () => import('@/views/NestedRoute.vue'),
        meta: {
            pageTitle: 'ICD',
            breadcrumb: [
                {
                    text: 'Master',
                },
                {
                    text: 'Pemeriksaan',
                },
                {
                    text: 'ICD',
                    active: true,
                },
            ],
        },
        children: [
            {
                path: '',
                name: 'mstPemeriksaanIcdGetData',
                component: () => import('@/views/mst/pemeriksaan/icd/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mst-pemeriksaan-icd',
                    pageTitle: 'ICD',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'ICD',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/pemeriksaan/icd/tambah',
                name: 'mstPemeriksaanIcdTambah',
                component: () => import('@/views/mst/pemeriksaan/icd/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mst-pemeriksaan-icd',
                    pageTitle: 'Tambah ICD',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'ICD',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/pemeriksaan/icd/ubah/:id',
                name: 'mstPemeriksaanIcdUbah',
                component: () => import('@/views/mst/pemeriksaan/icd/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mst-pemeriksaan-icd',
                    pageTitle: 'Ubah ICD',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'ICD',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]