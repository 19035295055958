export default [
    {
        path: '/tagihan',
        redirect: '/tagihan',
        name: 'tagihan',
        component: () => import('@/views/NestedRoute.vue'),
        meta: {
            pageTitle: 'Tagihan',
            breadcrumb: [
                {
                    text: 'Tagihan',
                    active: true,
                },
            ],
        },
        children: [
            {
                path: '',
                name: 'tagihanIndex',
                component: () => import('@/views/tagihan/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'tagihan',
                    pageTitle: 'Tagihan',
                    breadcrumb: [
                        {
                            text: 'Tagihan',
                            active: true,
                        },
                    ],
                    action: 'read',
                    resource: 'tagihan',
                },
            },
            {
                path: '/tagihan/tambah',
                name: 'tagihanTambah',
                component: () => import('@/views/tagihan/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'tagihan',
                    pageTitle: 'Tambah Tagihan',
                    breadcrumb: [
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                    action: 'create',
                    resource: 'tagihan',
                },
            },
            {
                path: '/tagihan/ubah/:id',
                name: 'tagihanUbah',
                component: () => import('@/views/tagihan/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'tagihan',
                    pageTitle: 'Ubah Tagihan',
                    breadcrumb: [
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: route => {
                    return {
                        action: 'Ubah',
                        id: route.params.id
                    }
                }
            },
            {
                path: '/tagihan/konfirmasi/:id',
                name: 'tagihanKonfirmasi',
                component: () => import('@/views/tagihan/Ubah.vue'),
                meta: {
                    action: 'konfirmasi',
                    resource: 'tagihan',
                    pageTitle: 'Konfirmasi Tagihan',
                    breadcrumb: [
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Konfirmasi',
                            active: true,
                        },
                    ],
                },
                props: route => {
                    return {
                        action: 'Konfirmasi',
                        id: route.params.id
                    }
                }
            },
            {
                path: '/tagihan/bayar/tambah/:id',
                name: 'tagihanBayarTambah',
                component: () => import('@/views/tagihan/bayar/Tambah.vue'),
                meta: {
                    action: 'Bayar',
                    resource: 'tagihan',
                    pageTitle: 'Bayar Tagihan',
                    breadcrumb: [
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Bayar',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]