export default [
    {
        path: '/mst/pemeriksaan/morphology',
        redirect: '/mst/pemeriksaan/morphology',
        name: 'mstPemeriksaanMorphology',
        component: () => import('@/views/NestedRoute.vue'),
        meta: {
            pageTitle: 'Morphology',
            breadcrumb: [
                {
                    text: 'Master',
                },
                {
                    text: 'Pemeriksaan',
                },
                {
                    text: 'Morphology',
                    active: true,
                },
            ],
        },
        children: [
            {
                path: '',
                name: 'mstPemeriksaanMorphologyGetData',
                component: () => import('@/views/mst/pemeriksaan/morphology/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mst-pemeriksaan-morphology',
                    pageTitle: 'Morphology',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Morphology',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/pemeriksaan/morphology/tambah',
                name: 'mstPemeriksaanMorphologyTambah',
                component: () => import('@/views/mst/pemeriksaan/morphology/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mst-pemeriksaan-morphology',
                    pageTitle: 'Tambah Morphology',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Morphology',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/pemeriksaan/morphology/ubah/:id',
                name: 'mstPemeriksaanMorphologyUbah',
                component: () => import('@/views/mst/pemeriksaan/morphology/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mst-pemeriksaan-morphology',
                    pageTitle: 'Ubah Morphology',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Pemeriksaan',
                        },
                        {
                            text: 'Morphology',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]