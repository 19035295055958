export default [
    {
        path: '/mjmn/pengguna',
        name: 'mjmn-pengguna',
        redirect: '/mjmn/pengguna',
        component: () => import('@/views/NestedRoute.vue'),
        meta: {
            pageTitle: 'Pengguna',
            breadcrumb: [
                {
                    text: 'Manajemen',
                },
                {
                    text: 'Pengguna',
                    active: true,
                },
            ],
        },
        children: [
            {
                path: '',
                name: 'mjmn-pengguna-get-data',
                component: () => import('@/views/mjmn/pengguna/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mjmn-pengguna',
                    pageTitle: 'Pengguna',
                    breadcrumb: [
                        {
                            text: 'Manajemen',
                        },
                        {
                            text: 'Pengguna',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mjmn/pengguna/tambah',
                name: 'mjmn-pengguna-tambah',
                component: () => import('@/views/mjmn/pengguna/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mjmn-pengguna',
                    pageTitle: 'Tambah Pengguna',
                    breadcrumb: [
                        {
                            text: 'Manajemen',
                        },
                        {
                            text: 'Pengguna',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mjmn/pengguna/ubah/:id',
                name: 'mjmn-pengguna-ubah',
                component: () => import('@/views/mjmn/pengguna/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mjmn-pengguna',
                    pageTitle: 'Ubah Pengguna',
                    breadcrumb: [
                        {
                            text: 'Manajemen',
                        },
                        {
                            text: 'Pengguna',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]