export default [
    {
        path: '/mst/tagihan/bank',
        redirect: '/mst/tagihan/bank',
        name: 'mstTagihanBank',
        component: () => import('@/views/NestedRoute.vue'),
        children: [
            {
                path: '',
                name: 'mstTagihanBankIndex',
                component: () => import('@/views/mst/tagihan/bank/Index.vue'),
                meta: {
                    action: 'read',
                    resource: 'mst-tagihan-bank',
                    pageTitle: 'Bank',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Bank',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/tagihan/bank/tambah',
                name: 'mstTagihanBankTambah',
                component: () => import('@/views/mst/tagihan/bank/Tambah.vue'),
                meta: {
                    action: 'create',
                    resource: 'mst-tagihan-bank',
                    pageTitle: 'Tambah Bank',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Bank',
                        },
                        {
                            text: 'Tambah',
                            active: true,
                        },
                    ],
                },
            },
            {
                path: '/mst/tagihan/bank/ubah/:id',
                name: 'mstTagihanBankUbah',
                component: () => import('@/views/mst/tagihan/bank/Ubah.vue'),
                meta: {
                    action: 'update',
                    resource: 'mst-tagihan-bank',
                    pageTitle: 'Ubah Bank',
                    breadcrumb: [
                        {
                            text: 'Master',
                        },
                        {
                            text: 'Tagihan',
                        },
                        {
                            text: 'Bank',
                        },
                        {
                            text: 'Ubah',
                            active: true,
                        },
                    ],
                },
                props: true
            },
        ]
    }
]